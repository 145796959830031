// eslint-disable-next-line
import axios from 'axios';

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export const getCategories = async () => {
  let response;

  if (isProduction) {
    response = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}/public/category/by?company=${process.env.REACT_APP_COMPANY_ID}`
    );
    response = response.data;
  } else {
    response = require('static/json/categories.json');
  }

  const categories = response || [];

  return categories;
};
