import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CEP from './search';
import { theme } from '../../../commons/style/theme';
import Logo from '../../../static/logos/logoMarrom.png';
import HomeCar from '../../../static/home.png';
import Store from '../../../static/store.png';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '25px',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
  p: 4,
};

export type EnderecoModoEntrega = {
  Rua: string;
  Numero: string;
  ModoEntrega: 'retirarNaLoja' | 'receberEmCasa';
};

type LocalizacaoOpcaoDialogProps = {
  onClose: () => void;
  onSelectedInfo: (info: EnderecoModoEntrega) => void;
  enderecoRua: string;
  enderecoNumero: string;
};

export default function LocalizacaoOpcaoDialog({
  onClose,
  onSelectedInfo,
  enderecoRua,
  enderecoNumero,
}: LocalizacaoOpcaoDialogProps) {
  const [selectedValue, setSelectedValue] = useState('');
  const [enderecoReferencia, setEnderecoReferencia] = useState<
    EnderecoModoEntrega | undefined
  >();

  const handleConfirmLocation = () => {
    if (enderecoReferencia) {
      onSelectedInfo(enderecoReferencia);
    } else {
      alert('Selecione uma opção primeiro!');
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <img
            src={Logo}
            style={{
              width: '100%',
              maxWidth: '300px',
              margin: '-110px auto',
              borderRadius: '15px',
              display: 'block',
            }}
            alt="logo"
          />
        </Box>

        <Typography
          variant="h6"
          sx={{
            pb: 1,
            color: theme.colors.primary,
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'bold',
          }}
        >
          No endereço,&nbsp;
          <span
            style={{
              fontFamily: 'Courier New, monospace',
              color: theme.colors.blackLow,
              fontSize: '14px',
            }}
          >
            {`${enderecoRua}, n° ${enderecoNumero}`}
          </span>
          &nbsp;você pode:
        </Typography>

        <Typography
          variant="subtitle2"
          sx={{
            mb: 2,
            color: theme.colors.tertiary,
            textAlign: 'center',
            fontFamily: 'unset',
          }}
          onClick={onClose}
        >
          Alterar meu endereço de entrega
        </Typography>

        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box sx={{ display: 'flex', gap: 2 }}>
            {/* Coluna Retirar na Loja */}
            <Box
              sx={{
                paddingBottom: '5%',
                flex: 1,
                borderRadius: '15px',
                border: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', pt: 4 }}>
                    <img src={Store} alt="Retire na loja" />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: theme.colors.primary,
                    }}
                  >
                    Retire grátis na loja <br /> mais próxima
                  </Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                    width: '80%',
                    maxHeight: '250px',
                    marginLeft: '12%',
                    marginBottom: '2%',
                    overflowY: 'auto',
                    borderRadius: '10px',
                    bgcolor: 'background.default',
                  }}
                >
                  <List
                    style={{
                      height: 120,
                    }}
                  >
                    {CEP.map((item, index) => (
                      <ListItem
                        key={index}
                        button
                        sx={{
                          borderBottom: '1px solid #ddd',
                          '&:last-of-type': { borderBottom: 'none' },
                          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
                        }}
                      >
                        <Radio
                          color="success"
                          checked={selectedValue === item.bairro}
                          onChange={() => {
                            setEnderecoReferencia({
                              ModoEntrega: 'retirarNaLoja',
                              Numero: item.numero,
                              Rua: item.rua,
                            });
                            setSelectedValue(item.bairro);
                          }}
                          value={item.bairro}
                          name="location-radio"
                        />
                        <Box sx={{ flex: 1, paddingLeft: 1 }}>
                          <Typography
                            sx={{
                              paddingTop: 1.5,
                              fontWeight: 'bold',
                              color: theme.colors.tertiary,
                            }}
                            variant="body1"
                          >
                            {item.label}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 'bold',
                              color: theme.colors.blackLow,
                            }}
                          >
                            Bairro: {item.bairro}
                          </Typography>
                          <Typography variant="caption">
                            Rua: {item.rua}
                          </Typography>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>

            {/* Coluna Entrega em Casa */}
            <Box
              sx={{
                flex: 1,
                borderRadius: '15px',
                border: '1px solid black',
                pl: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Box sx={{ display: 'flex', pt: 4 }}>
                  <img src={HomeCar} alt="Receber em casa" />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme.colors.primary,
                  }}
                >
                  Receba em casa
                </Typography>
              </Box>

              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <FormControl
                  sx={{
                    display: 'flex',
                    mb: 2,
                    justifyContent: 'center',
                    pl: 2,
                  }}
                >
                  <RadioGroup
                    aria-labelledby="delivery-options"
                    name="delivery-options"
                    row
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value)}
                  >
                    <FormControlLabel
                      value="receberEmCasa"
                      control={<Radio color="success" />}
                      onClick={() => {
                        setEnderecoReferencia({
                          ModoEntrega: 'receberEmCasa',
                          Numero: enderecoNumero,
                          Rua: enderecoRua,
                        });
                        setSelectedValue('receberEmCasa');
                      }}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <LocalShippingIcon fontSize="large" color="primary" />
                          <Typography
                            variant="caption"
                            sx={{
                              color: 'tertiary.main',
                              fontSize: '10px',
                              textAlign: 'center',
                              marginTop: '4px',
                            }}
                          >
                            Entrega <br /> agendada
                          </Typography>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <Box
                  sx={{
                    width: '90%',
                    marginLeft: '120px',
                    mt: -11.5,
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 'bold',
                      mb: 0.5,
                      color: theme.colors.tertiary,
                    }}
                  >
                    A partir de 17 de Setembro <br /> das 08h às 20h
                  </Typography>{' '}
                  <br />
                  <Typography variant="caption">
                    Prazo de entrega conforme <br /> agendamento
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>

        <Typography
          variant="body2"
          sx={{
            mt: 1,
            color: theme.colors.tertiary,
            textAlign: 'center',
            fontFamily: 'unset',
          }}
        >
          Selecione a modalidade de entrega para prosseguir
        </Typography>

        <Box
          sx={{
            mt: 1,
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'center',
          }}
        >
          <Button
            variant="contained"
            style={{
              textAlign: 'center',
              color: '#fff',
              borderRadius: '15px',
              padding: '10px 41.5%',
              fontWeight: 'bold',
              background: theme.colors.primary,
            }}
            onClick={handleConfirmLocation}
            sx={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}
            disabled={!enderecoReferencia}
          >
            Ir às compras
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
