import { Container } from '@mui/material';
import * as S from './style';
import { Carousel } from 'antd';

const Carrousel = () => {
  return (
    <Container style={{ marginTop: 30 }}>
      <Carousel
        autoplay
        dots={true}
        centerMode={false}
        slidesToShow={1}
        slidesToScroll={1}
        infinite={true}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        <S.CarrouselItem>
          <S.Image src={require('static/banners/banner2.png')} alt="banner2" />
        </S.CarrouselItem>
        <S.CarrouselItem>
          <S.Image src={require('static/banners/banner3.png')} alt="banner3" />
        </S.CarrouselItem>
        <S.CarrouselItem>
          <S.Image src={require('static/banners/banner4.png')} alt="banner4" />
        </S.CarrouselItem>
      </Carousel>
    </Container>
  );
};

export default Carrousel;
