import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;

interface LoaderItemProps {
  index: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column; /* Empilha os itens verticalmente */
  align-items: center;
  justify-content: center;
  height: 100vh; /* Altura total da viewport */
  width: 100vw; /* Largura total da viewport */
  position: fixed; /* Fixa o modal na tela */
  top: 0; /* Alinha ao topo */
  left: 0; /* Alinha à esquerda */
  background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
  z-index: 2000; /* Coloca acima de outros elementos */
`;

export const LoaderWrapper = styled.div`
  display: flex; /* Mantém os itens do loader em linha */
  flex-direction: row; /* Permite que os itens sejam dispostos horizontalmente */
  align-items: center;
`;

export const LoaderItem = styled.div<LoaderItemProps>`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  animation: ${bounce} 0.6s infinite alternate;
  animation-delay: ${({ index }) => `${index * 0.1}s`};
`;

export const Message = styled.p`
  margin-top: 20px; /* Espaçamento entre o loader e a mensagem */
  font-size: 18px; /* Tamanho da fonte */
  color: #fff; /* Cor da fonte (ajuste conforme seu tema) */
  text-align: center; /* Centraliza o texto */
`;
