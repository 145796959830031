import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCart } from 'contexts/cart-context';
import { theme } from 'commons/style/theme';
import { MenuBook, WhatsApp } from '@mui/icons-material';

const BottomNavbar = () => {
  const { total, openCart } = useCart();
  const [value, setValue] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 'carrinho') {
      openCart();
    }
    if (newValue === 'whatsapp') {
      window.location.href = `https://api.whatsapp.com/send?phone=${theme.numbers.whatsapp}&text=Olá%2C%20vim%20através%20do%20site%20e%20gostaria%20de%20um%20orçamento`
    }

    if (newValue === 'cardapio') {
      window.location.href = "https://www.canva.com/design/DAFf1vxBEw4/fewbt5FSQuqwoicAmVYenA/edit"
    }
  };

  return (
    <BottomNavigation
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 100,
        boxShadow: '0 -1px 10px rgba(0, 0, 0, 0.2)',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        overflow: 'hidden',
      }}
      value={value}
      onChange={handleChange}
      showLabels
    >
      <BottomNavigationAction
        label="Cardápio"
        icon={<MenuBook sx={{ color: theme.colors.primary }} />}
        value="cardapio"
        sx={{
          transition: 'color 0.3s',
          '&:hover': {
            color: theme.colors.secondary,
          },
        }}
      />
      <BottomNavigationAction
        label="Carrinho"
        icon={
          <Badge
            badgeContent={total.productQuantity}
            color="primary" 
            max={999}
            showZero
            sx={{
              marginTop: 1,
              '& .MuiBadge-dot': {
                backgroundColor: theme.colors.secondary,
                boxShadow: '0 0 4px rgba(0,0,0,0.2)',
              },
            }}
          >
            <ShoppingCartIcon sx={{ color: theme.colors.primary }} />
          </Badge>
        }
        value="carrinho"
        sx={{
          transition: 'color 0.3s',
          '&:hover': {
            color: theme.colors.secondary,
          },
        }}
      />
      <BottomNavigationAction
        label="WhatsApp"
        icon={<WhatsApp sx={{ color: theme.colors.primary }} />}
        value="whatsapp"
        sx={{
          transition: 'color 0.3s',
          '&:hover': {
            color: theme.colors.secondary,
          },
        }}
      />
    </BottomNavigation>
  );
};

export default BottomNavbar;
