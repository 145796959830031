import { useProducts } from 'contexts/products-context';
import Chip from '@material-ui/core/Chip';
import { ICategory } from 'models';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'commons/style/theme';

type FilterProps = {
  categories: ICategory[];
};

const Filter = ({ categories }: FilterProps) => {
  const { filters, filterProducts } = useProducts();
  const selectedCheckboxes = new Set(filters);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.mobile})`);

  const toggleCheckbox = (id: string) => {
    if (selectedCheckboxes.has(id)) {
      selectedCheckboxes.delete(id);
    } else {
      selectedCheckboxes.add(id);
    }

    const updatedFilters = Array.from(selectedCheckboxes);
    filterProducts(updatedFilters);
  };

  const createCheckbox = (category: ICategory) => {
    const isSelected = selectedCheckboxes.has(category.id);
    return (
      <Chip
        label={category.name}
        onClick={() => toggleCheckbox(category.id)}
        key={category.id}
        variant="default"
        style={{
          marginBottom: 5,
          background: isSelected ? theme.colors.primary : theme.colors.white,
          color: isSelected ? theme.colors.white : theme.colors.primary,
          borderColor: theme.colors.primary,
          borderWidth: '1px',
          borderStyle: 'dotted',
        }}
      />
    );
  };

  return isMobile ? (
    <Box ml={2} mt={3} sx={{ position: 'relative' }}>
      <Typography variant="inherit">Opções:</Typography>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          gap: 2,
          mt: 1,
          p: 2,
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {categories.map(createCheckbox)}
      </Box>

      <Box
        sx={{
          position: 'fixed',
          top: 'calc(50% + 40px)',
          right: '10px',
          width: '50px',
          height: '50px',
          pointerEvents: 'none',
        }}
      />
    </Box>
  ) : (
    <Box>
      <Typography variant="inherit">Opções:</Typography>
      <Box
        mt={3}
        style={{ display: 'flex', flexDirection: 'column', width: 'auto' }}
      >
        {categories.map(createCheckbox)}
      </Box>
    </Box>
  );
};

export default Filter;
