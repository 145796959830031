import React, { useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import { IProduct } from 'models';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Modal,
  CardActions,
  useMediaQuery,
} from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { theme } from 'commons/style/theme';
import formatPrice from 'utils/formatPrice';
import { ImageProductUrls } from 'utils/imageProductUrls';
import { Option } from './productCard';
import CustomSelect from './customSelect';
import { useCart } from 'contexts/cart-context';
import confetti from 'canvas-confetti';
import { AddCircleOutline } from '@mui/icons-material';
import DefaultImage from '../../../static/logos/logoMarrom.png';

const styleDesktop = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 820,
  height: 480,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 2,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 300,
  bgcolor: 'background.paper',
  borderRadius: 2.5,
  boxShadow: 24,
  p: 1,
};

type ProductDialogProps = {
  product: IProduct;
  onClose: () => void;
};

export default function ProductDialog({
  product,
  onClose,
}: ProductDialogProps) {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.mobile})`);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const [productPrice, setProductPrice] = useState<number>(product.price);
  const { openCart, addProduct } = useCart();

  const formattedPrice = formatPrice(productPrice, product.currency_id);
  const imageList = ImageProductUrls(product.sku);

  useEffect(() => {
    if (product.has_product_type) {
      const initialPrice = selectedOption
        ? selectedOption.price
        : product.types[0]?.values[0]?.price || product.price;
      setProductPrice(initialPrice);
    } else {
      setProductPrice(product.price);
    }
  }, [selectedOption, product.has_product_type, product.types, product.price]);

  const handleAddProduct = () => {
    addProduct({
      ...product,
      quantity: 1,
      price: productPrice,
      selectedSubtype: selectedOption?.value || '',
    });
    openCart();
    confetti({ particleCount: 100, spread: 70 });
  };

  const renderInstallments = () => {
    if (product.installments) {
      const installmentPrice = productPrice / product.installments;
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Typography variant="body2" color={theme.colors.blackLow}>
            em até
          </Typography>
          <Typography variant="body1" color="#04a54f">
            <span>
              {product.installments}x {product.currency_format}
              {formatPrice(installmentPrice, product.currency_id)}{' '}
            </span>
          </Typography>
        </Box>
      );
    }
  };

  const renderProductTypeOptions = () => {
    return (
      product.has_product_type &&
      product.types.map((type) => (
        <Box key={type.id}>
          <Typography variant="overline">{type.name}</Typography>
          <CustomSelect
            options={type.values.map((value) => ({
              label: value.id,
              value: value.value,
              price: value.price,
            }))}
            onSelect={(option) => setSelectedOption(option)}
          />
        </Box>
      ))
    );
  };

  return (
    <React.Fragment>
      <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isMobile ? (
          <Box sx={styleDesktop}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
                justifyContent: 'center',
              }}
            >
              <Card sx={{ width: '60%', height: '100%', borderRadius: 3 }}>
                <Box>
                  <Carousel showThumbs={false} showStatus={false}>
                    {imageList.map((image, index) => (
                      <CardMedia
                        sx={{ padding: '5px', borderRadius: 3 }}
                        component="img"
                        width={550}
                        height={350}
                        image={image}
                        alt={`Imagem ${index + 1}`}
                        key={index}
                      />
                    ))}
                  </Carousel>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {imageList.length === 1 ? (
                    <>
                      <CardMedia
                        component="img"
                        image={imageList[0]}
                        alt="Imagem 1"
                        sx={{
                          width: 190,
                          height: 120,
                          borderRadius: 7,
                          padding: '5px',
                        }}
                      />
                      <CardMedia
                        component="img"
                        image={DefaultImage}
                        alt="Imagem padrão"
                        sx={{
                          width: 190,
                          height: 120,
                          borderRadius: 7,
                          padding: '5px',
                        }}
                      />
                    </>
                  ) : (
                    imageList.map((image, index) => (
                      <CardMedia
                        key={index}
                        component="img"
                        image={image}
                        alt={`Imagem ${index + 1}`}
                        sx={{
                          width: 190,
                          height: 120,
                          borderRadius: 7,
                          padding: '5px',
                        }}
                      />
                    ))
                  )}
                </Box>
              </Card>

              <Card
                sx={{
                  width: '60%',
                  minHeight: '100%',
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardContent>
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      textAlign: 'center',
                      width: 'auto',
                      padding: '5px',
                      borderRadius: 2,
                      fontSize: '13px',
                      backgroundColor: product.is_schedule
                        ? theme.colors.primary
                        : '#7CD033',
                      color: theme.colors.white,
                    }}
                  >
                    <AccessTimeIcon fontSize="small" sx={{ paddingRight: 1 }} />
                    {product.is_schedule
                      ? `Agendamento c/ ${product.waiting_schedule}`
                      : 'Pronta entrega'}
                  </Typography>
                  <Typography
                    paddingTop={3}
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: 'bold', display: 'flex' }}
                  >
                    {product.title}
                  </Typography>
                  <Typography
                    paddingTop={1}
                    sx={{
                      color: theme.colors.tertiary,
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                  >
                    {product.description}
                  </Typography>
                  <Typography
                    paddingTop={2}
                    variant="h4"
                    color={theme.colors.blackLow}
                  >
                    <small>{product.currency_format}</small>
                    <b>{formattedPrice.slice(0, -3)}</b>
                    <span style={{ fontSize: 18 }}>
                      {formattedPrice.slice(-3)}
                    </span>
                  </Typography>
                  <Box>{renderInstallments()}</Box>
                  <Box sx={{ paddingTop: 2, mb: 5 }}>
                    {renderProductTypeOptions()}
                  </Box>
                </CardContent>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginRight={2}
                  marginBottom={1}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleAddProduct();
                      onClose();
                    }}
                    size="small"
                    disabled={product.has_product_type && !selectedOption}
                    style={{
                      backgroundColor:
                        product.has_product_type && !selectedOption
                          ? theme.colors.secondary
                          : theme.colors.primary,
                      borderRadius: 10,
                      padding: 5,
                      width: 220,
                      fontSize: 12,
                      color: theme.colors.white,
                      textAlign: 'center',
                    }}
                  >
                    <AddCircleOutline
                      fontSize="small"
                      sx={{ paddingRight: 1 }}
                    />
                    {product.has_product_type && !selectedOption
                      ? 'Selecione uma opção'
                      : 'Adicionar ao carrinho'}
                  </Button>
                </Box>
              </Card>
            </Box>
          </Box>
        ) : (
          <Box sx={styleMobile}>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '2px',
                borderRadius: 1.5,
                fontSize: '11px',
                marginLeft: '5px',
                backgroundColor: product.is_schedule
                  ? theme.colors.primary
                  : '#7CD033',
                color: theme.colors.white,
              }}
            >
              <AccessTimeIcon fontSize="inherit" sx={{ paddingRight: 1 }} />
              {product.is_schedule
                ? `Agendamento c/ ${product.waiting_schedule}`
                : 'Pronta entrega'}
            </Typography>
            <Carousel showThumbs={false} showStatus={false}>
              {imageList.map((image, index) => (
                <CardMedia
                  style={{ color: theme.colors.primary }}
                  sx={{ padding: '5px', borderRadius: 5 }}
                  component="img"
                  width={'auto'}
                  height="210"
                  image={image}
                  alt={`Imagem ${index + 1}`}
                  key={index}
                />
              ))}
            </Carousel>
            <CardContent>
              <Typography
                id="modal-modal-title"
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  display: 'flex',
                  textAlign: 'center',
                }}
              >
                {product.title}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 1,
                  fontSize: '13px',
                  color: theme.colors.blackLow,
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                {product.description}
              </Typography>

              <Box sx={{ mt: 1, justifyContent: 'center', display: 'flex' }}>
                {renderProductTypeOptions()}
              </Box>
            </CardContent>

            <Typography
              variant="h5"
              color={theme.colors.blackLow}
              sx={{ textAlign: 'center' }}
            >
              <small>{product.currency_format}</small>
              <b>{formattedPrice.slice(0, -3)}</b>
              <span style={{ fontSize: 15 }}>{formattedPrice.slice(-3)}</span>
            </Typography>
            <Box sx={{ paddingLeft: 10 }}>{renderInstallments()}</Box>

            <CardActions sx={{ justifyContent: 'center', paddingTop: 2 }}>
              <Button
                variant="contained"
                onClick={() => {
                  handleAddProduct();
                  onClose();
                }}
                size="small"
                disabled={product.has_product_type && !selectedOption}
                style={{
                  backgroundColor:
                    product.has_product_type && !selectedOption
                      ? theme.colors.secondary
                      : theme.colors.primary,
                  padding: 5,
                  borderRadius: 10,
                  fontSize: '12px',
                  color: theme.colors.white,
                  textAlign: 'center',
                }}
              >
                <LocalGroceryStoreRoundedIcon
                  fontSize="small"
                  sx={{ paddingRight: 1 }}
                />
                {product.has_product_type && !selectedOption
                  ? 'Selecione uma opção'
                  : 'Adicionar ao carrinho'}
              </Button>
            </CardActions>
          </Box>
        )}
      </Modal>
    </React.Fragment>
  );
}
