const CEP = [
  { label: 'Itajaí', bairro: 'Santa Regina', rua: 'Rua José Francisco Reis', numero: '1000' },
  { label: 'Itajaí', bairro: 'Portal 1', rua: 'Rua Hildo Silva', numero: '1000' },
  { label: 'Itajaí', bairro: 'Portal 2', rua: 'Rua Alto Braço do Baú', numero: '1000' },
  { label: 'Itajaí', bairro: 'Fazenda', rua: 'Rua Heitor Liberato', numero: '1000' },
  { label: 'Itajaí', bairro: 'Centro', rua: 'Rua Airton Cena', numero: '1000' },
  { label: 'Itajaí', bairro: 'Ressacada', rua: 'Rua Hercilio Luz', numero: '1000' },
  { label: 'Itajaí', bairro: 'Cabeçudas', rua: 'Rua 7 de Setembro', numero: '1000' },
  { label: 'Itajaí', bairro: 'Santa Regina', rua: 'Rua José Francisco Reis', numero: '1000' },
];

export default CEP;
