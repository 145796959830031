export const ImageProductUrls = (sku: number): string[] => {
  const getImageUrl1 = () =>
    process.env.REACT_APP_NODE_ENV === 'production'
      ? `${process.env.REACT_APP_AWS_S3_URL_PREFIX}/${process.env.REACT_APP_COMPANY_ID}-${sku}-1.webp`
      : `${require(`static/products/${sku}-1-product.webp`)}`;

  const getImageUrl2 = () =>
    process.env.REACT_APP_NODE_ENV === 'production'
      ? `${process.env.REACT_APP_AWS_S3_URL_PREFIX}/${process.env.REACT_APP_COMPANY_ID}-${sku}-2.webp`
      : `${require(`static/products/${sku}-2-product.webp`)}`;

  const listaImagens: string[] = [getImageUrl1(), getImageUrl2()];

  return listaImagens;
};