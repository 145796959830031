import Carrousel from './Carrousel';
import Navbar from './Navbar';
import BottomNavbar from 'components/Layout/BottomNavbar';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'commons/style/theme';
import { ICategory, IProduct } from 'models';

interface IProps {
  products: IProduct[];
  categories: ICategory[];
}

const Hero = ({ products, categories }: IProps) => {
  const isDesktop = useMediaQuery(`(min-width:${theme.breakpoints.desktop})`);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="17vh"
        bgcolor="#cb6a7d"
        position="relative"
      >
        <Navbar products={products} categories={categories} />
      </Box>
      {isDesktop ? null : <BottomNavbar />}
      <Carrousel />
    </Box>
  );
};

export default Hero;
