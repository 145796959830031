import { ICategory, IProduct } from 'models';
import { Box, Container, useMediaQuery } from '@mui/material';
import ProductCard from './Product/productCard';
import { useMemo, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { theme } from 'commons/style/theme';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

interface IProps {
  products: IProduct[];
  categories: ICategory[];
}

const Products = ({ products, categories }: IProps) => {
  const groupedProducts = useMemo(() => {
    const grouped: { [key: string]: IProduct[] } = {};
    products.forEach((p) => {
      const category = p.category.id;
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(p);
    });
    return grouped;
  }, [products]);

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile})`);
  const [activeIndex, setActiveIndex] = useState<{ [key: string]: number }>({});

  const categoryMap = useMemo(() => {
    const map = new Map<string, string>();
    categories.forEach((cat) => {
      map.set(cat.id, cat.name);
    });
    return map;
  }, [categories]);

  const handleChange = (category: string, newIndex: number) => {
    setActiveIndex((prev) => ({
      ...prev,
      [category]: newIndex,
    }));
  };

  const renderGroupedProducts = () => {
    return Object.keys(groupedProducts).map((category) => {
      const totalSlides = groupedProducts[category].length;

      return (
        <Container key={category}>
          <Box
            component="h1"
            mt={3}
            sx={{
              fontSize: '16px',
              marginBottom: '10px',
              color: theme.colors.tertiary,
              [`@media (max-width: ${theme.breakpoints.mobile})`]: {
                fontSize: '15px',
                marginLeft: '10px',
              },
              [`@media (max-width: ${theme.breakpoints.tablet})`]: {
                marginLeft: '10px',
              },
            }}
          >
            {categoryMap.get(category) || 'Categoria Padrão'}
          </Box>

          {isMobile && totalSlides > 1 ? (
            <Carousel
              autoPlay={false}
              indicators={false}
              navButtonsAlwaysVisible
              index={activeIndex[category] || 0}
              onChange={(currentIndex) =>
                handleChange(category, currentIndex || 0)
              }
              sx={{
                color: theme.colors.primary,
                backgroundColor: 'transparent',
                overflow: 'hidden',
                position: 'relative',
                width: '100%',
              }}
              navButtonsProps={{
                style: {
                  width: '35px',
                  height: '35px',
                  backgroundColor: 'transparent',
                },
              }}
              navButtonsWrapperProps={{
                style: {
                  backgroundColor: 'transparent',
                  pointerEvents:
                    activeIndex[category] === 0 ||
                    activeIndex[category] === totalSlides - 1
                      ? 'auto'
                      : 'auto',
                },
              }}
              NextIcon={
                activeIndex[category] === totalSlides - 1 ? null : (
                  <ArrowForwardIos
                    sx={{ fontSize: '18px', color: theme.colors.quartiary }}
                  />
                )
              }
              PrevIcon={
                activeIndex[category] === 0 ? null : (
                  <ArrowBackIosNew
                    sx={{ fontSize: '18px', color: theme.colors.quartiary }}
                  />
                )
              }
            >
              {groupedProducts[category].map((p, idx) => (
                <Box
                  key={p.id}
                  sx={{
                    display: 'inline-block',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <ProductCard product={p} />
                </Box>
              ))}
            </Carousel>
          ) : (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 0.5,
                [`@media (max-width: ${theme.breakpoints.mobile})`]: {
                  gridTemplateColumns: 'repeat(1, 1fr)',
                },
                [`@media (min-width: ${theme.breakpoints.tablet}) and (max-width: ${theme.breakpoints.desktop})`]:
                  {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  },
              }}
            >
              {groupedProducts[category].map((p) => (
                <ProductCard product={p} key={p.id} />
              ))}
            </Box>
          )}
        </Container>
      );
    });
  };

  return <>{renderGroupedProducts()}</>;
};

export default Products;
