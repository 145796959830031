import { ReactChild, ReactFragment, ReactPortal } from 'react';
import {
  Card as MuiCard,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const CardDesktop = (props: {
  IconAlt: string | undefined;
  Icon: string | undefined;
  rootClassName: any;
  Header: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  return (
    <MuiCard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        borderRadius: 4,
        height: 'auto',
      }}
    >
      <CardMedia
        component="img"
        image={props.Icon}
        alt={props.IconAlt}
        sx={{ width: 35, height: 35, marginBottom: 1 }}
      />
      <CardContent className={props.rootClassName} sx={{ padding: 0 }}>
        <Typography
          variant="h6"
          component="div"
          align="center"
          sx={{
            fontSize: 16
          }}
        >
          {props.Header}
        </Typography>
      </CardContent>
    </MuiCard>
  );
};

CardDesktop.propTypes = {
  rootClassName: PropTypes.string,
  Icon: PropTypes.string,
  IconAlt: PropTypes.string,
  Header: PropTypes.string,
};

export default CardDesktop;
