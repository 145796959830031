import * as S from './style';

const Loader = () => (
  <S.Container>
    <S.LoaderWrapper>
      {Array.from({ length: 4 }).map((_, index) => (
        <S.LoaderItem key={index} index={index} />
      ))}
    </S.LoaderWrapper>
    <S.Message>Aguarde, estamos preparando tudo para você ;)</S.Message>
  </S.Container>
);

export default Loader;
