import React, { useState, useEffect } from 'react';
import { UseModalFacilityOrder } from 'contexts/facility-context/useModalFacilityOrder';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'commons/style/theme';
import CardMobile from './Card/CardMobile';
import { motion, AnimatePresence } from 'framer-motion';
import CardDesktop from 'components/Soluctions/Card/CardDesktop';

const Soluctions = () => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.mobile})`);

  const cards = [
    {
      Icon: require('static/cardsIcons/01.png'),
      Header: 'Escolher o tipo do bolo',
      IconAlt:
        'Escolher o tipo do bolo',
    },
    {
      Icon: require('static/cardsIcons/02.png'),
      Header: 'Selecione o recheio',
      IconAlt:
        'Selecione o recheio',
    },
    {
      Icon: require('static/cardsIcons/03.png'),
      Header: 'Selecione a decoração',
      IconAlt:
        'Selecione a decoração',
    },
    {
      Icon: require('static/cardsIcons/04.png'),
      Header: 'Agende a data de entrega',
      IconAlt:
        'Agende a data de entrega',
    },
  ];

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isMobile, cards.length]);

  return (
    <Container id="solutions">
      <Box mt={5}>
        <Typography
          variant="h6"
          component="div"
          mb={2}
          sx={{
            fontSize: isMobile ? 14 : 16,
            fontWeight: 'bold',
            textAlign: isMobile ? 'center' : 'left',
            color: theme.colors.tertiary,
          }}
        >
          Crie o seu bolo confeitado com o nosso criador inteligente
        </Typography>
      </Box>

      {/* Renderiza os cards em grid no desktop */}
      {!isMobile ? (
        <Grid container spacing={2} onClick={() => setOpen(true)}>
          {cards.map((card, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <CardDesktop
                Icon={card.Icon}
                Header={card.Header}
                IconAlt={card.IconAlt}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        // Renderiza os cards em grid no mobile
        <div onClick={() => setOpen(true)}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.4 }}
              transition={{ duration: 1 }}
            >
              <CardMobile
                Icon={cards[activeIndex].Icon}
                Header={cards[activeIndex].Header}
                IconAlt={cards[activeIndex].IconAlt}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      )}

      {open && <UseModalFacilityOrder setOpen={setOpen} open={open} />}
    </Container>
  );
};

export default Soluctions;
