import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  height: auto;
  margin: 2% 12%;

  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    filter: brightness(85%);
  }

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
    breakpoints.tablet}) and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    margin: 2% 0%;
  }
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.tablet}) {
    margin: 5% 0;
  }

`;

export const CarrouselItem = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  max-width: 100%;
  max-height: 100%;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
    height: 15vh;
}
`;

export const Title = styled.h4`
  margin-top: 2px;
  margin-bottom: 20px;
`;
