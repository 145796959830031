import { useCallback, useEffect, useState } from 'react';
import { useProductsContext } from './ProductsContextProvider';
import { IProduct } from 'models';
import { getProducts } from 'services/products';

const useProducts = () => {
  const {
    isFetching,
    setIsFetching,
    products,
    setProducts,
    filters,
    setFilters,
  } = useProductsContext();

  const [allProducts, setAllProducts] = useState<IProduct[]>([]);

  const fetchProducts = useCallback(async () => {
    setIsFetching(true);
    const products = await getProducts();
    setIsFetching(false);
    setAllProducts(products);
    setProducts(products);
  }, [setIsFetching, setProducts]);

  const filterProducts = useCallback((filters: string[]) => {
    setFilters(filters);

    const filteredProducts = filters.length
      ? allProducts.filter((p: IProduct) =>
          filters.includes(p.category.id)
        )
      : allProducts;

    setProducts(filteredProducts);
  }, [allProducts, setFilters, setProducts]);

  useEffect(() => {
    if (!allProducts.length) {
      fetchProducts();
    }
  }, [allProducts, fetchProducts]);

  return {
    isFetching,
    fetchProducts,
    products,
    filterProducts,
    filters,
  };
};

export default useProducts;
