import { ReactChild, ReactFragment, ReactPortal } from 'react';
import {
  Card as MuiCard,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const CardMobile = (props: {
  IconAlt: string | undefined;
  Icon: string | undefined;
  rootClassName: any;
  Header: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  return (
    <MuiCard
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        padding: 1,
        height: '100%',
      }}
    >
      <CardMedia
        component="img"
        image={props.Icon}
        alt={props.IconAlt}
        sx={{ width: 35, height: 35, marginRight: 1 }}
      />
      <CardContent
        className={props.rootClassName}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: 16,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.Header}
        </Typography>
      </CardContent>
    </MuiCard>
  );
};

CardMobile.propTypes = {
  rootClassName: PropTypes.string,
  Icon: PropTypes.string,
  IconAlt: PropTypes.string,
  Header: PropTypes.string,
};

export default CardMobile;
