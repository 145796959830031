import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';

/* Antd */
import { ConfigProvider } from 'antd';

/* Theme */
import { ThemeProvider } from 'commons/style/styled-components';
import { theme } from 'commons/style/theme';
import {
  ThemeProvider as ThemeProviderMui,
  createTheme,
} from '@mui/material/styles';

import GlobalStyle from 'commons/style/global-style';

/* Context Providers */
import { ProductsProvider } from 'contexts/products-context';
import { CartProvider } from 'contexts/cart-context';

import App from 'components/App';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const root = document.getElementById('root')!;
const container = ReactDOMClient.createRoot(root);

const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.tertiary,
    },
    secondary: {
      main: theme.colors.secondary,
    },
  },
  typography: {},
  breakpoints: {
    values: {
      xs: parseInt(theme.breakpoints.mobile),
      sm: parseInt(theme.breakpoints.tablet),
      md: parseInt(theme.breakpoints.desktop),
      lg: 1280,
      xl: 1920,
    },
  },
});

const localeText = {
  cancelButtonLabel: 'Cancelar',
  okButtonLabel: 'Ok',
  toolbarTitle: 'Selecione uma data',
  todayButtonLabel: 'Hoje',
  dateTableLabel: 'Data',
  timeTableLabel: 'Hora',
};

container.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.colors.primary,
          },
        }}
      >
        <ThemeProviderMui theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} localeText={localeText}>
            <GlobalStyle />
            <ProductsProvider>
              <CartProvider>
                <App />
              </CartProvider>
            </ProductsProvider>
          </LocalizationProvider>
        </ThemeProviderMui>
      </ConfigProvider>
    </ThemeProvider>
  </StrictMode>
);
