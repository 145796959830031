import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Menu,
  Card,
  CardContent,
  Typography,
  MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import SellIcon from '@mui/icons-material/Sell';
import { theme } from '../../../commons/style/theme';
import ProductDialog from 'components/Products/Product/productDialog';
import { ICategory, IProduct } from 'models';
import { ImageProductUrls } from 'utils/imageProductUrls';

interface IProps {
  products: IProduct[];
  categories: ICategory[];
}

const MenuCategories = ({ products, categories }: IProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [dialog, setDialog] = useState<JSX.Element | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const groupedProducts = useMemo(() => {
    const grouped: { [key: string]: IProduct[] } = {};
    products.forEach((p) => {
      const category = p.category.id;
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(p);
    });
    return grouped;
  }, [products]);

  const categoryMap = useMemo(() => {
    const map = new Map<string, string>();
    categories.forEach((cat) => {
      map.set(cat.id, cat.name);
    });
    return map;
  }, [categories]);

  const handleCategoryClick = (
    category: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setSelectedCategory(category);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCategory(null);
  };

  const getProductsByCategory = (category: string) => {
    return products.filter((product) => product.category.id === category);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      minWidth="100%"
      position="relative"
    >
      <Box>
        <Button
          variant="text"
          onClick={(event) => handleCategoryClick('Todas as categorias', event)}
          sx={{
            fontSize: '12px',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontWeight: 'bold',
            color: theme.colors.white,
          }}
        >
          <MenuIcon sx={{ paddingRight: '5px' }} />
          Todas as categorias
        </Button>
        <Menu
          onClose={handleClose}
          open={Boolean(anchorEl) && selectedCategory === 'Todas as categorias'}
          anchorEl={anchorEl}
        >
          <Box display="flex" flexDirection="column" px={1}>
            {Object.keys(groupedProducts)
              .slice(0, 5)
              .map((category) => (
                <MenuItem key={category}>
                  <Card
                    key={category}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      textAlign: 'center',
                      width: '120px',
                      padding: '8px',
                      boxShadow: 3,
                      mb: 0.5,
                      '&:hover': {
                        boxShadow: 10,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        padding: 0,
                        '&:last-child': {
                          paddingBottom: 0,
                        },
                      }}
                    >
                      <Button
                        style={{
                          padding: '5px 8px',
                        }}
                        sx={{
                          fontSize: '10px',
                          fontFamily: 'Inter, Roboto, sans-serif',
                          fontWeight: 'bold',
                          color: theme.colors.tertiary,
                          textTransform: 'uppercase',
                          border: '1px solid',
                          borderColor: theme.colors.tertiary,
                        }}
                        onClick={(event) =>
                          handleCategoryClick(category, event)
                        }
                      >
                        {categoryMap.get(category) || 'Categoria Padrão'}
                      </Button>
                    </CardContent>
                  </Card>
                </MenuItem>
              ))}
          </Box>
        </Menu>
      </Box>

      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        minWidth={'48vw'}
      >
        {Object.keys(groupedProducts).map((category) => (
          <Box key={category} ml={4}>
            <Button
              sx={{
                fontSize: '12px',
                fontFamily: 'Inter, Roboto, sans-serif',
                fontWeight: '500',
                color: theme.colors.white,
                '&:hover': {
                  backgroundColor: theme.colors.primary,
                  borderRadius: '5px',
                },
              }}
              variant="text"
              onClick={(event) => handleCategoryClick(category, event)}
            >
              {categoryMap.get(category) || 'Categoria Padrão'}
              <ArrowDropDownIcon />
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedCategory === category}
              onClose={handleClose}
              sx={{ mt: 2 }}
            >
              <Box display="flex" flexDirection="column" px={1}>
                {getProductsByCategory(category).map((product) => {
                  const imageList = ImageProductUrls(product.sku);

                  const maxLength = 30;
                  const isTruncated = product.description.length > maxLength;
                  const truncatedText = isTruncated
                    ? product.description.substring(0, maxLength) + '...'
                    : product.description;
                  return (
                    <Card
                      key={product.id}
                      onClick={() =>
                        setDialog(
                          <ProductDialog
                            product={product as any}
                            onClose={() => setDialog(null)}
                          />
                        )
                      }
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: 'auto',
                        height: 'auto',
                        mb: 1.5,
                        p: 1,
                        borderRadius: '8px',
                        boxShadow: 2,
                        cursor: 'pointer',
                        transition: 'box-shadow 0.2s ease',
                        backgroundColor: theme.colors.white,
                        '&:hover': {
                          boxShadow: 6,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '8px',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexShrink: 0,
                        }}
                      >
                        <img
                          src={imageList[0]}
                          alt={product.title}
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </Box>

                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          ml: 2,
                          flex: 1,
                          padding: 0,
                          '&:last-child': { paddingBottom: 0 },
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: '14px',
                            fontFamily: 'Inter, Roboto, sans-serif',
                            fontWeight: 'bold',
                            color: theme.colors.primary,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {product.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Inter, Roboto, sans-serif',
                            color: 'text.secondary',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            mt: 0.5,
                          }}
                        >
                          {truncatedText}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '13px',
                            fontFamily: 'Inter, Roboto, sans-serif',
                            fontWeight: 'bold',
                            color: theme.colors.tertiary,
                            mt: 1,
                          }}
                        >
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(product.price)}
                        </Typography>

                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: '11px',
                            fontFamily: 'Inter, Roboto, sans-serif',
                            color: theme.colors.secondary,
                            mt: 1,
                            textAlign: 'center',
                          }}
                        >
                          Clique para detalhes
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
            </Menu>
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        right={0}
      >
        <Button
          variant="text"
          sx={{
            fontSize: '12px',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontWeight: 'bold',
            color: theme.colors.white,
          }}
        >
          <SellIcon sx={{ paddingRight: '5px' }} />
          Ofertas
        </Button>
      </Box>
      {dialog}
    </Box>
  );
};

export default MenuCategories;
